import React, { useContext, useEffect, useState, useRef } from 'react'
import { EmployeeContext } from '../../contexts/EmployeeContext'
import { ThemeContext } from '../../contexts/ThemeContext'
import styles from "./AccountsView.module.scss"
import cx from 'classnames'
import EmployeeItem from './EmployeeItem'
import { Menu, Dialog } from "@mui/material"
import { GetAllUsers, ChangeUserAccess, GetAllowedPages } from '../../services/authServices'
import { Check } from "react-feather";
import ThreeDots from "../../assets/media/three-dots.png"

interface Test {
  Status: boolean,
  Id: number,
  Abilities: Array<any>
}

interface AllowedPages {
  "Accounts" : Test,
  "AddEditContracts" : Test,
  "Benchmarks" : Test,
  "Automations" : Test,
  "AutomationsDemo" : Test,
  "Projects" : Test,
  "ContractDashboard" : Test,
  "ContractDatabase" : Test,
  "LeadManagement" : Test,
  "Onboarding" : Test,
  "PitchStatistics" : Test,
  "VacationBoard" : Test,
  "TrackingDemo" : Test,
  "Processes" : Test
}

const AccountsView = () => {
  const [activeTab, setActiveTab] = useState("active");
  const [accessRequests, setAccessRequests] = useState([]);
  const [accessDialog, setAccessDialog] = useState(false);
  const [allowedPages, setAllowedPages] = useState<AllowedPages>({
    "Accounts" : { Status: false, Id: 0, Abilities: [] },
    "AddEditContracts" : { Status: false, Id: 0, Abilities: [] },
    "Benchmarks" : { Status: false, Id: 0, Abilities: [] },
    "Automations" : { Status: false, Id: 0, Abilities: [] },
    "AutomationsDemo" : { Status: false, Id: 0, Abilities: [] },
    "Projects" : { Status: false, Id: 0, Abilities: [] },
    "ContractDashboard" : { Status: false, Id: 0, Abilities: [] },
    "ContractDatabase" : { Status: false, Id: 0, Abilities: [] },
    "LeadManagement" : { Status: false, Id: 0, Abilities: [] },
    "Onboarding" : { Status: false, Id: 0, Abilities: [] },
    "PitchStatistics" : { Status: false, Id: 0, Abilities: [] },
    "VacationBoard" : { Status: false, Id: 0, Abilities: [] },
    "TrackingDemo" : { Status: false, Id: 0, Abilities: [] },
    "Processes" : { Status: false, Id: 0, Abilities: [] },
  });
  const [userAbilities, setUserAbilities] = useState<string | null>(null);
  const employees = useContext(EmployeeContext).employees;  
  const theme = useContext(ThemeContext);   
  const initialAllowedPages = useRef<AllowedPages | null>()

  const getAccessRequests = async () => {
    await GetAllUsers()
    .then((res: any) => {
      if (res.length > 0) {
        var requests = res.filter((user: any) => user.role == 0);
        setAccessRequests(requests);        
      }
    });

    await GetAllowedPages()
    .then((res: any) => {      
      setAllowedPages(res);
      initialAllowedPages.current = res;
    })
  }
  
  useEffect(() => {
    getAccessRequests()
  }, [])

  useEffect(() => {
    console.log(allowedPages)
  }, [allowedPages])

  const handleAccessDialog = () => {
    if (!accessDialog) {
      setAccessDialog(true);
    }
    else {
      setAccessDialog(false);
      setUserAbilities(null)
      if (initialAllowedPages.current) {
        setAllowedPages(initialAllowedPages.current)
      }
    }
  }

  const handleAccessPages = (page: string) => {    
    setAllowedPages((prev) => ({
      ...prev,
      [page] : {
        ...allowedPages[page as keyof AllowedPages],
        Status: allowedPages[page as keyof AllowedPages].Status ? false : true         
      }
    }))
  }  

  const handleUserAbilities = (id: number, pageId: number) => {    
    let page: any = Object.entries(allowedPages).find(([key, val]) => val.Id == pageId);   
    let title = page[0]
    let values = page[1]
    
    let abilities = values.Abilities.map((item: any) => {
      if (item.Id == id) {
        return {
          ...item,
          Status: item.Status ? false : true
        }
      }
      else {
        return item
      }
    })

    values = {
      ...values,
      Abilities: abilities
    }

    setAllowedPages((prev) => ({
      ...prev,
      [title]: values
    }))
  }

  const changeUserAccess = async () => {
    await ChangeUserAccess(allowedPages)
    .then((res: any) => {
      if (res.status == 200) {
        setAccessDialog(false);
        setUserAbilities(null);

        var accessNotif = document.getElementById("access-notification");              
        accessNotif?.classList.add(styles.visible);

        setTimeout(() => {
          if (accessNotif) {
            accessNotif.style.opacity = '0';            
            setTimeout(() => {
              accessNotif?.classList.remove(styles.visible);              
            }, 500);       
          }
        }, 3000)
      }
    })
  }

  return (
    <div className={cx(styles.container, theme.darkMode && styles.darkMode)}>
      <h1 className={styles.title}>Solutions Accounts</h1>
      <div style={{ marginBottom: '50px' }}>
        <div className={styles.tabHeader}>
          <div className={cx(styles.tab, activeTab == "active" && styles.active, theme.darkMode && styles.darkMode)} onClick={() => setActiveTab("active")}>
            <p>Active Users</p>
          </div>
          <div className={cx(styles.tab, activeTab == "requests" && styles.active, theme.darkMode && styles.darkMode)} onClick={() => setActiveTab("requests")}>            
              <p style={{ margin: '0 10px 0 0'}}>Requests</p>
              {
                accessRequests.length > 0 && (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '17px', width: '17px', backgroundColor: 'red', borderRadius: '50px' }}><p style={{ fontSize: '0.65rem', margin: '0 0 0 -1px', color: '#FFFFFF' }}>{accessRequests.length}</p></div>
                )
              }            
          </div>
        </div>
        {
          activeTab == "active" && (
            <>
              <p style={{ fontSize: '1rem', margin: '0px', fontWeight: 'bold' }}>{employees.length} People</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p onClick={() => handleAccessDialog()} className={styles.accessButton}>Manage User Access</p>
              </div>
            </>
          )
        }
      </div>      
      {        
        activeTab == "active" ? (
          employees.map((employee: any) => {            
            var role = employee.role == 1 ? 'User' : 'Admin'
            let dateJoined = employee.dateJoined;

            return (
              <EmployeeItem key={employee.id} name={employee.name} email={employee.email} role={role} photo={employee.photo} id={employee.id} company={employee.company} dateJoined={dateJoined} />              
            )
          })
        ) : (          
          accessRequests.map((user: any) => {
            var role = "Pending Request";
            let dateJoined = user.dateJoined;
            
            return (
              <EmployeeItem key={user.id} name={user.name} email={user.email} role={role} photo={null} id={user.id} company={user.company} dateJoined={dateJoined}/>
            )
          })
        )        
      }
      <Dialog
        open={accessDialog}
        onClose={() => handleAccessDialog()}
        sx={{
          "& .MuiPaper-root": {
            // height: "50vh",
            width: "60vw",
            padding: "30px 40px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            boxSizing: "border-box",
            textAlign: 'center'
          },
        }}         
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>          
          <div className={styles.accessDialogHeader}>
            <h1>User Access</h1>
            <p>Manage what users (non-Admins) can access on Abintus Solutions</p>
          </div>                
        </div>
        <div style={{ width: '100%', textAlign: 'left' }}>
          {
            !userAbilities ? (
              <ul className={styles.accessList}>
                <p style={{ marginBottom: '10px', fontWeight: '700' }}>Contracts</p>  
                <li>
                  <div style={{ display: 'flex' }}>
                    <input type="checkbox" checked={allowedPages["ContractDatabase"].Status} onChange={() => handleAccessPages("ContractDatabase")} />
                    <p>Contract Database</p>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input type="checkbox" checked={allowedPages["ContractDashboard"].Status} onChange={() => handleAccessPages("ContractDashboard")} />
                    <p>Contract Dashboard</p>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input type="checkbox" checked={allowedPages["AddEditContracts"].Status} onChange={() => handleAccessPages("AddEditContracts")} />
                    <p>Add/Edit Contracts</p>
                  </div>
                </li>
                <p style={{ marginBottom: '10px', fontWeight: '700' }}>Analytics</p>  
                <li>
                  <div style={{ display: 'flex' }}>
                    <input type="checkbox" checked={allowedPages["Benchmarks"].Status} onChange={() => handleAccessPages("Benchmarks")} />
                    <p>Benchmarks</p>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input type="checkbox" checked={allowedPages["PitchStatistics"].Status} onChange={() => handleAccessPages("PitchStatistics")} />
                    <p>Pitch Statistics</p>
                  </div>                    
                </li>
                <p style={{ marginBottom: '10px', fontWeight: '700' }}>Productivity</p>  
                <li>
                  <div style={{ display: 'flex' }}>
                    <input type="checkbox" checked={allowedPages["Automations"].Status} onChange={() => handleAccessPages("Automations")} />
                    <p>Automations</p>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input type="checkbox" checked={allowedPages["AutomationsDemo"].Status} onChange={() => handleAccessPages("AutomationsDemo")}/>
                    <p>Automations</p>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input type="checkbox" checked={allowedPages["Projects"].Status} onChange={() => handleAccessPages("Projects")}/>
                    <p>Projects</p>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }} id={styles.processes}>
                    <input type="checkbox" checked={allowedPages["Processes"].Status} onChange={() => handleAccessPages("Processes")}/>
                    <p style={{ marginRight: '10px' }}>Processes</p>
                    <img src={ThreeDots} id={styles.dotMenu} onClick={() => setUserAbilities("Processes")} />
                  </div>
                </li>
                <p style={{ marginBottom: '10px', fontWeight: '700' }}>Employees</p>  
                <li>
                  <div style={{ display: 'flex' }}>
                    <input type="checkbox" checked={allowedPages["VacationBoard"].Status} onChange={() => handleAccessPages("VacationBoard")} />
                    <p>Vacation Board</p>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input type="checkbox" checked={allowedPages["Onboarding"].Status} onChange={() => handleAccessPages("Onboarding")}/>
                    <p>Onboarding</p>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input type="checkbox" checked={allowedPages["Accounts"].Status} onChange={() => handleAccessPages("Accounts")}/>
                    <p>Accounts</p>
                  </div>
                </li>
                <p style={{ marginBottom: '10px', fontWeight: '700' }}>Clients</p>  
                <li>
                  <div style={{ display: 'flex' }}>
                    <input type="checkbox" checked={allowedPages["LeadManagement"].Status} onClick={() => handleAccessPages("LeadManagement")} />
                    <p>Lead Management</p>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <input type="checkbox" checked={allowedPages["TrackingDemo"].Status} onClick={() => handleAccessPages("TrackingDemo")} />
                    <p>Tracking Demo</p>
                  </div>                    
                </li>
              </ul>
            ) : (
                <div style={{ width: '100%', textAlign: 'left' }}>
                  <p style={{ marginBottom: '10px', fontWeight: '700' }}>Manage what users can do on the {userAbilities} page</p>
                  <ul>
                    {
                      allowedPages[userAbilities as keyof AllowedPages].Abilities.map((item: any) => {
    
                        return (
                          <li style={{ paddingLeft: '15px' }}>
                            <div style={{ display: 'flex' }}>
                              <input type="checkbox" checked={item.Status} onChange={() => handleUserAbilities(item.Id, item.PageId)} style={{ margin: "0 10px 0 0" }} />
                              <p>{item.Title}</p>
                            </div>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              )
            }
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <button onClick={() => changeUserAccess()} style={{ backgroundColor: "#000000", color: "#FFFFFF" }} className={styles.dialogButton} >Save Changes</button>
            <button onClick={() => handleAccessDialog()} style={{ border: 'solid 1px #cacaca', backgroundColor: 'transparent', color: "#000000" }} className={styles.dialogButton} >Cancel</button>
          </div>
        </div>
      </Dialog>
      <div className={styles.accessNotification} id="access-notification">
        <Check color="#40B73A" style={{ marginRight: '10px' }} />
        <p>Your changes have been saved</p>
      </div>
    </div>
  )
}

export default AccountsView
