import React, { useState, useContext, useEffect } from "react";
import styles from "./Sidebar.module.scss";
import { Drawer, Hidden, Backdrop } from "@mui/material";
import SidebarItem from "./SidebarItem";
import cx from "classnames";
import SidebarChildren from "./SidebarChildren";
import { Logout, GetLeadCharts, GetAllowedPages } from "../../services/authServices";
import { useNavigate } from "react-router-dom";
import HomeWhite from "../../assets/media/home-white.png";
import HomeBlack from "../../assets/media/home-black.png";
import DBWhite from "../../assets/media/db-white.png";
import DBBlack from "../../assets/media/db-black.png";
import DashWhite from "../../assets/media/dash-white.png";
import DashBlack from "../../assets/media/dash-black.png";
import EditWhite from "../../assets/media/edit-white.png";
import EditBlack from "../../assets/media/edit-black.png";
import BalanceWhite from "../../assets/media/balance-white.png";
import BalanceBlack from "../../assets/media/balance-black.png";
import LineGraphWhite from "../../assets/media/line-white.png";
import LineGraphBlack from "../../assets/media/line-black.png";
import FileWhite from "../../assets/media/file-white.png";
import FileBlack from "../../assets/media/file-black.png";
import FileDemoWhite from "../../assets/media/file-demo-white.png";
import FileDemoBlack from "../../assets/media/file-demo-black.png";
import TableWhite from "../../assets/media/table-white.png";
import TableBlack from "../../assets/media/table-black.png";
import FireWhite from "../../assets/media/fire-white.png";
import FireBlack from "../../assets/media/fire-black.png";
import TreeWhite from "../../assets/media/tree-white.png";
import TreeBlack from "../../assets/media/tree-black.png";
import HandshakeWhite from "../../assets/media/handshake-white.png";
import HandshakeBlack from "../../assets/media/handshake-black.png";
import PeopleWhite from "../../assets/media/people-white.png";
import PeopleBlack from "../../assets/media/people-black.png";
import TrackingBlack from "../../assets/media/tracking-black.png";
import TrackingWhite from "../../assets/media/tracking-white.png";
import ProcessesWhite from "../../assets/media/processes-white.png";
import ProcessesBlack from "../../assets/media/processes-black.png";
import ContactWhite from "../../assets/media/contact-white.png";
import ContactBlack from "../../assets/media/contact-black.png";
import { ThemeContext } from "../../contexts/ThemeContext";
import { AuthContext } from "../../contexts/AuthContext";


interface SidebarProps {
  openMobile: boolean;
  closeMobile: any;
  collapseMenu: any;
}

interface LeadChart {
  Id: number,
  Title: string,
  Creator: string,
}

interface AllowedPages {
  "Accounts" : boolean,
  "AddEditContracts" : boolean,
  "Benchmarks" : boolean,
  "ContractDashboard" : boolean,
  "ContractDatabase" : boolean,
  "LeadManagement" : boolean,
  "Onboarding" : boolean,
  "PitchStatistics" : boolean,
  "VacationBoard" : boolean,
}

interface ResVal {
  Status?: any,
}

const Sidebar: React.FC<SidebarProps> = ({
  openMobile,
  closeMobile,
  collapseMenu,
}) => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const [leadCharts, setLeadCharts] = useState<LeadChart[]>();
  const [allowedPages, setAllowedPages] = useState<any>(null);
  const themeContext = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  var navigate = useNavigate();

  const getLeads = async () => {
    var test: any = await GetLeadCharts();
    
    var leadCharts: LeadChart[] = [];
    test.forEach((item: any) => {
      leadCharts.push({
        Id: item.Id,
        Title: item.Title,
        Creator: item.Creator
      })
    })
    
    setLeadCharts(leadCharts)
  }

  const getAllowedPages = async () => {
    await GetAllowedPages()
    .then((res: any) => {    
      setAllowedPages(res);      
    })
  }

  useEffect(() => {
    getLeads()
    getAllowedPages();
  }, [])

  const CollapseMenu = () => {
    setIsMenuCollapsed((prev) => !prev);
    collapseMenu();
  };

  const handleLogout = () => {
    Logout();
    navigate("/");
  };

  var contractItems = [
    {
      title: "Contract Database",
      href: "/dashboard/contracts/contract-database",
      iconLight: DBWhite,
      iconDark: DBBlack,
      dbName: "ContractDatabase"
    },
    {
      title: "Contract Dashboard",
      href: "/dashboard/contracts/contract-dashboard",
      iconLight: DashWhite,
      iconDark: DashBlack,
      dbName: "ContractDashboard"
    },
    {
      title: "Add/Edit Contract",
      href: "/dashboard/contracts/add-edit-contract",
      iconLight: EditWhite,
      iconDark: EditBlack,
      dbName: "AddEditContracts"
    },
  ];

  var analyticsItems = [
    {
      title: "Benchmarks",
      href: "null",
      iconLight: BalanceWhite,
      iconDark: BalanceBlack,
      subitems: [
        { href: "/dashboard/benchmarks/avbs", title: "AVB Benchmarks" },
        { href: "/dashboard/benchmarks/price-and-quality", title: "MPQ" },
        { href: "/dashboard/benchmarks/technical-costs", title: "Technical Costs" },
        { href: "/dashboard/benchmarks/remunerations", title: "Remunerations" },
        { href: "/dashboard/benchmarks/wfa-inflation", title: "WFA Inflation" },
      ],      
      dbName: "Benchmarks"
    },
    {
      title: "Pitch Statistics",
      href: "/dashboard/pitch-statistics",
      iconLight: LineGraphWhite,
      iconDark: LineGraphBlack,
      dbName: "PitchStatistics"
    },
  ];

  var productivityItems = [
    {
      title: "Automations",
      href: "/dashboard/automations",
      iconLight: FileWhite,
      iconDark: FileBlack,      
    },
    {
      title: "Automations Demo",
      href: "/dashboard/automations-demo",
      iconLight: FileDemoWhite,
      iconDark: FileDemoBlack,
    },
    {
      title: "Projects",
      href: "/dashboard/task-manager/overview",
      iconLight: TableWhite,
      iconDark: TableBlack,
    },    
    {
      title: "Processes",
      href: "/dashboard/processes",
      iconLight: ProcessesWhite,
      iconDark: ProcessesBlack
    },
    {
      title: "Media Agency Contacts",
      href: "/dashboard/media-agency-contacts",
      iconLight: ContactWhite,
      iconDark: ContactBlack,
    }
  ];

  var employeeItems = [
    {
      title: "Vacation Board",
      href: "/dashboard/vacation-board",
      iconLight: TreeWhite,
      iconDark: TreeBlack,
      dbName: "VacationBoard"
    },
    {
      title: "Onboarding",
      href: "/dashboard/onboarding",
      iconLight: HandshakeWhite,
      iconDark: HandshakeBlack,
      dbName: "Onboarding"
    },
    {
      title: "Accounts",
      href: "/dashboard/solutions-accounts",
      iconLight: PeopleWhite,
      iconDark: PeopleBlack,
      dbName: "Accounts"
    }
  ]

  var clientItems = [
    {
      title: "Lead Management",
      href: "null",
      iconLight: FireWhite,
      iconDark: FireBlack,
      subitems: leadCharts?.map(item => {        
        return (
          { href: `/dashboard/lead-management/${item.Id}`, title: item.Title }
        )
      }),
      dbName: "LeadManagement"
    },
    {
      title: "Tracking Demo",
      href: "/dashboard/tracking-demo",
      iconLight: TrackingWhite,
      iconDark: TrackingBlack,
      dbName: "TrackingDemo"
    }
  ]

  return (
    <>
      <Hidden mdUp>
        <Backdrop open={openMobile} onClick={closeMobile}>
          <Drawer
            anchor="bottom"
            open={openMobile}
            onClose={closeMobile}
            variant="temporary"
            className={styles.mobileContainer}
            style={{
              height: "75%",
              bottom: 0,
              top: "auto",
              backgroundColor: "#FFFFFF",
            }}
          ></Drawer>
        </Backdrop>
      </Hidden>
      <Hidden mdDown>
        <div
          className={cx(
            styles.container,
            isMenuCollapsed && styles.collapsed,
            themeContext.darkMode && styles.darkMode,
          )}
        >
          <div
            className={cx(
              styles.sidebarHeader,
              isMenuCollapsed && styles.collapsed,
              themeContext.darkMode && styles.darkMode,
            )}
          >
            <div style={{ paddingTop: "5px" }}>
              <h3 style={{ fontSize: '1rem'}}>Menu</h3>
              <p>{Date().toString().split(" ").slice(1, 4).join(" ")}</p>
            </div>
            <div className={styles.burgerIcon} onClick={CollapseMenu}>
              <div
                style={{
                  backgroundColor: themeContext.darkMode
                    ? "#FFFFFF"
                    : "#000000",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: themeContext.darkMode
                    ? "#FFFFFF"
                    : "#000000",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: themeContext.darkMode
                    ? "#FFFFFF"
                    : "#000000",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: themeContext.darkMode
                    ? "#FFFFFF"
                    : "#000000",
                }}
              ></div>
            </div>
          </div>
          <div className={cx(styles.content)}>
            {isMenuCollapsed ? (
              <div
                style={{
                  paddingTop: "10px",
                  marginBottom: "40px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    marginBottom: "10px",
                    height: "20px",
                    width: "20px",
                  }}
                  src={themeContext.darkMode ? HomeWhite : HomeBlack}
                  alt="icon"
                />
              </div>
            ) : (
              <a
                href="/dashboard"
                style={{ height: "40px" }}
                className={styles.sidebarSubitem}
              >
                <img
                  src={themeContext.darkMode ? HomeWhite : HomeBlack}
                  alt="icon"
                />
                <p>Home</p>
              </a>
            )}
            <div style={{ marginBottom: "30px" }}>
              {!isMenuCollapsed && (
                <h1 className={styles.sectionHeader}>CONTRACTS</h1>
              )}
              {
                allowedPages !== null && (
                  contractItems.map((item, i) => {                    
                    if (allowedPages[item.dbName].Status || auth.user[7] == 2) {
                      if (isMenuCollapsed) {
                        return (
                          <div
                            key={item.href}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{
                                marginBottom: "10px",
                                height: "20px",
                                width: "20px",
                              }}
                              src={
                                themeContext.darkMode ? item.iconLight : item.iconDark
                              }
                              alt="icon"
                            />
                          </div>
                        );
                      } else {
                        return (
                          <a href={item.href} className={styles.sidebarSubitem} key={item.title}>
                            <img
                              src={
                                themeContext.darkMode ? item.iconLight : item.iconDark
                              }
                              alt="icon"
                            />
                            <p>{item.title}</p>
                          </a>
                        );
                      }
                    }
                  })
                )
              }
            </div>
            <div style={{ marginBottom: "30px" }}>
              {!isMenuCollapsed && (
                <h1 className={styles.sectionHeader}>ANALYTICS</h1>
              )}
              { allowedPages !== null && (
                analyticsItems.map((item, i) => {
                if (allowedPages[item.dbName].Status || auth.user[7] == 2) {
                  if (isMenuCollapsed) {
                    return (
                      <div
                        key={item.title}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            marginBottom: "10px",
                            height: "20px",
                            width: "20px",
                          }}
                          src={
                            themeContext.darkMode ? item.iconLight : item.iconDark
                          }
                          alt="icon"
                        />
                      </div>
                    );
                  } else {
                    if (item.subitems) {
                      return (
                        <SidebarChildren
                          title={item.title}
                          iconDark={item.iconDark}
                          iconLight={item.iconLight}
                          children={item.subitems}
                          key={item.title}
                        />
                      );
                    } else {
                      return (
                        <a href={item.href} className={styles.sidebarSubitem} key={item.title}>
                          <img
                            src={
                              themeContext.darkMode
                                ? item.iconLight
                                : item.iconDark
                            }
                            alt="icon"
                          />
                          <p>{item.title}</p>
                        </a>
                      );
                    }
                  }
                }
              }))}
            </div>
          </div>
          <div style={{ marginBottom: "30px" }}>
            {!isMenuCollapsed && (
              <h1 className={styles.sectionHeader}>PRODUCTIVITY</h1>
            )}
            {productivityItems.map((item, i) => {
              if (isMenuCollapsed) {
                return (
                  <div
                    key={item.href}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        marginBottom: "10px",
                        height: "20px",
                        width: "20px",
                      }}
                      src={
                        themeContext.darkMode ? item.iconLight : item.iconDark
                      }
                      alt="icon"
                    />
                  </div>
                );
              } else {
                return (
                  <a href={item.href} className={styles.sidebarSubitem} key={item.title}>
                    <img
                      src={
                        themeContext.darkMode ? item.iconLight : item.iconDark
                      }
                      alt="icon"
                    />
                    <p>{item.title}</p>
                  </a>
                );
              }
            })}
          </div>
          <div style={{ marginBottom: '30px' }}>
            {!isMenuCollapsed && (
              <h1 className={styles.sectionHeader}>EMPLOYEES</h1>
            )}
            {
              allowedPages !== null && (
                employeeItems.map((item, i) => {
                  if (allowedPages[item.dbName].Status || auth.user[7] == 2) {
                    if (isMenuCollapsed) {
                      return (
                        <div
                          key={item.href}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{
                              marginBottom: "10px",
                              height: "20px",
                              width: "20px",
                            }}
                            src={
                              themeContext.darkMode ? item.iconLight : item.iconDark
                            }
                            alt="icon"
                          />
                        </div>
                      )
                    }
                    else {
                      return (
                        <a href={item.href} className={styles.sidebarSubitem} key={item.title}>
                          <img
                            src={
                              themeContext.darkMode ? item.iconLight : item.iconDark
                            }
                            alt="icon"
                          />
                          <p>{item.title}</p>
                        </a>
                      )
                    }
                  }
                })
              )
            }
          </div>
          <div>          
            {!isMenuCollapsed && (
              <h1 className={styles.sectionHeader}>CLIENTS</h1>
            )}
            {              
              allowedPages !== null && (

                clientItems.map((item, i) => {
                  if (allowedPages[item.dbName].Status || auth.user[7] == 2) {
                    if (isMenuCollapsed) {
                      return (
                        <div
                          key={item.title}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{
                              marginBottom: "10px",
                              height: "20px",
                              width: "20px",
                            }}
                            src={
                              themeContext.darkMode ? item.iconLight : item.iconDark
                            }
                            alt="icon"
                          />
                        </div>
                      );
                    } else {
                      if (item.subitems) {
                        if (item.title == "Lead Management") {
                          item.subitems.push({ href: 'test', title: '+ New'})
                        }                    
                        return (
                          <SidebarChildren
                            title={item.title}
                            iconDark={item.iconDark}
                            iconLight={item.iconLight}
                            children={item.subitems}
                            key={item.title}
                          />
                        );                      
                      } else {
                        return (
                          <a href={item.href} className={styles.sidebarSubitem} key={item.title}>
                            <img
                              src={
                                themeContext.darkMode
                                  ? item.iconLight
                                  : item.iconDark
                              }
                              alt="icon"
                            />
                            <p>{item.title}</p>
                          </a>
                        );
                      }
                    }
                  }
                })
              )
            }     
          </div>               
          <div
            className={cx(
              styles.logOut,
              isMenuCollapsed && styles.collapsed,
              themeContext.darkMode && styles.darkMode,
            )}
            onClick={() => handleLogout()}
          >
            <p style={{ margin: 0 }}>Log Out</p>
          </div>
        </div>
      </Hidden>
    </>
  );
};

export default Sidebar;
