import axios from "axios";
var url: string = "http://localhost:5000/";
// var url: string = "https://python-scripts-api.vercel.app/"

export const InHouseBuy = () => {
    fetch(url + "in-house-buy")
        .then((res: any) => res.json())
        .then((data) => {
            console.log(data);
        })
}

export const Reconciliation2024 = () => {
    fetch(url + "reconciliation-2024")
        .then((res: any) => res.json())
        .then((data) => {
            console.log(data);
        })
}

export const AgencyBuy = () => {
    fetch(url + "agency-buy")
        .then((res: any) => res.json())
        .then((data) => {
            console.log(data);
        })
}

export const NLAgencyBuy = () => {
    fetch(url + "netherlands-agency-buy")
        .then((res: any) => res.json())
        .then((data) => {
            console.log(data);
        })
}

export const NLDirectBuy = () => {
    fetch(url + "netherlands-direct-buy")
        .then((res: any) => res.json())
        .then((data) => {
            console.log(data);
        })
}

export const CompetitiveAnalysis = () => {
    fetch(url + "competitive-analysis")
        .then((res: any) => res.json())
        .then((data) => {
            console.log(data);
        })
}