import React from 'react'
import styles from "./styles/ScriptsView.module.scss"
import { 
  InHouseBuy, 
  Reconciliation2024, 
  AgencyBuy, 
  NLAgencyBuy, 
  NLDirectBuy, 
  CompetitiveAnalysis 
} from '../../services/scriptServices'

const ScriptsView = () => {
  return (
    <div className={styles.container}>
      <button style={{ margin: '5px' }} onClick={() => InHouseBuy()}>In-House Buy</button>
      <button style={{ margin: '5px' }} onClick={() => Reconciliation2024()}>Reconciliation 2024</button>
      <button style={{ margin: '5px' }} onClick={() => AgencyBuy()}>Agency Buy</button>
      <button style={{ margin: '5px' }} onClick={() => NLAgencyBuy()}>NL Agency Buy</button>
      <button style={{ margin: '5px' }} onClick={() => NLDirectBuy()}>NL Direct Buy</button>
      <button style={{ margin: '5px' }} onClick={() => CompetitiveAnalysis()}>Competitive Analysis</button>
    </div>
  )
}

export default ScriptsView
